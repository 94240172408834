import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import CaseStudyTemplate from '../../components/CaseStudyTemplate';

// assets
import Interaction1 from '../../images/case-study/chrono/interaction1.mp4';
import Interaction2 from '../../images/case-study/chrono/interaction2.mp4';
import Interaction3 from '../../images/case-study/chrono/interaction3.mp4';
import Interaction4 from '../../images/case-study/chrono/interaction4.mp4';
import Interaction5 from '../../images/case-study/chrono/interaction5.mp4';
import Interaction6 from '../../images/case-study/chrono/interaction6.mp4';
import Interaction7 from '../../images/case-study/chrono/interaction7.mp4';
import Result from '../../images/case-study/chrono/result.svg';

const ChronoCaseStudy = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "case-study/chrono/logo.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      hero: file(relativePath: { eq: "case-study/chrono/hero.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      problem: file(relativePath: { eq: "case-study/chrono/problem.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  const caseStudyData = {
    logo: data.logo.childImageSharp.gatsbyImageData,
    name: 'Chrono',
    date: 'Oct 2021',
    title: (
      <div className="banner__title">
        Save money in{' '}
        <span className="banner__title--highlight">tax residency</span>, visa
        tracking and chores
      </div>
    ),
    platforms: ['phone', 'web'],
    appLinks: [
      { type: 'play-store', path: '/' },
      { type: 'app-store', path: '/' },
    ],
    thumbnail: data.hero.childImageSharp.gatsbyImageData,
    description:
      'To visualize and amplify the idea of saving money on tax residency, we had to come up with a unique approach. People do not easily trust services with money, and to create that trust was a big hurdle. ',
    expandCTA: 'We love to solve',
    problem: [
      {
        text: 'Since the main idea of the app was around statistics, we had to come up with a unique and non straining layout for both the app and webapp. Also, we had to fit everything with a great visual UI with the given colors. So, it wasn’t a cakewalk by any means ( really ).',
        image: Interaction1,
      },
      {
        text: 'We had to create multiple flows and layouts which helped us better understand the big picture. Finalizing with a color scheme ( it’s never quick :) ) and examining multiple statistics took some time but we finished it ( no seriously we did ).',
        image: data.problem.childImageSharp.gatsbyImageData,
      },
    ],
    method: {
      text: 'We love creating wireframes to better understand the flow of things. Also, clients find it easy to communicate their thoughts with us and it helps us bring our ideas to life eventually.',
      image: Interaction2,
    },
    design: [
      {
        title: 'MOBILE APP',
        text: 'We set on a journey of thousand frames  ( pun intended ) and created a smooth flow to implement our solution.',
        image: Interaction3,
      },
      { imageOne: Interaction4, imageTwo: Interaction5 },
      {
        title: 'WEBAPP',
        text: 'We also created a webapp for the users to onboard and learn about the chrono app. Simple and effective.',
        image: Interaction6,
      },
      {
        text: 'The webapp helps you create, organize and manage tasks efficiently with a clean dashboard implementation',
        image: Interaction7,
      },
    ],
    result: {
      text: 'This is the final design that our team came up with. It was an awesome experience for us. “ With great project comes great responsibilities “',
      image: Result,
    },
    next: ['moja', 'flexnest'],
  };

  return (
    <>
      <CaseStudyTemplate {...caseStudyData} />
    </>
  );
};

export default ChronoCaseStudy;
